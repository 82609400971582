import * as React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../i18n/i18n.constants';
import { NefrologiaYDialisisSasInfo } from '../../constants';
import ToggleLanguage from '../ToggleLanguage/ToggleLanguage';
import { useEffect } from 'react';
import { getCookie, setCookie } from '../../services/cookieUtils';

const COOKIE_NAME = 'userConsent';
const COOKIE_EXPIRATION_DAYS = 365;

const Cookie = (): JSX.Element => {
  const { domain, legalName } = NefrologiaYDialisisSasInfo;
  const { t } = useTranslation(namespaces.components);

  const [open, setOpen] = React.useState<boolean>(false);

  const handleAccept = (): void => {
    setCookie(COOKIE_NAME, 'accepted', COOKIE_EXPIRATION_DAYS);
    setOpen(false);
  };

  useEffect(() => {
    const userConsent = getCookie(COOKIE_NAME);
    if (!userConsent) {
      setOpen(true);
    }
  }, []);

  return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"   
      >
        <Box sx={{padding: 1}}>
        <DialogTitle id="alert-dialog-title" color="primary">
        <ToggleLanguage />
          {t('cookie.title')}   
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" variant="body2" textAlign="justify">
            <Typography mb={2}>
              {t('cookie.description.part1', {
                thisWebsite: `${domain} | ${legalName}`,
              })}
          </Typography>
            <Typography  mb={2}>
              {t('cookie.description.part2')}
            </Typography>
            <Typography  mb={2}>
              {t('cookie.description.part3')}
            </Typography>
          </DialogContentText>
          
        </DialogContent>
        <DialogActions >
          <Button onClick={handleAccept}  variant="contained">
            {t('cookie.button')}
          </Button>
        </DialogActions>
        </Box>
      </Dialog>
  );
};

export default Cookie;
